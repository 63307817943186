import * as React from 'react';
import { graphql } from 'gatsby';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';

import GQL from '../graphql-types';
import Products from '../components/Products/Products';
import Banner from '../components/Banner/Banner';

interface IndexData {
  products: GQL.ContentfulProductConnection;
  page: GQL.ContentfulPageConnection;
  banners: GQL.ContentfulBannerConnection;
}

export interface IndexProps {
  data: IndexData;
}

const IndexPage = ({ data }: IndexProps) => {
  const { t } = useTranslation();
  const titleRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const scrollToRef = (ref: React.MutableRefObject<HTMLInputElement>) =>
    window.scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
  const executeScroll = () => scrollToRef(titleRef);

  return (
    <IndexLayout>
      {/*<Page>*/}
        <Banner banners={data.banners} scroll={executeScroll} />
        {/*<div ref={titleRef}>*/}
        {/*  <Container>*/}
        {/*    <Typography.Title level={1} style={{ textAlign: 'center', marginTop: '40px' }}>*/}
        {/*      {t('shell:mainPage.products')}*/}
        {/*    </Typography.Title>*/}
        {/*    <Products items={data.products.edges.map(p => p.node)} />*/}
        {/*  </Container>*/}
        {/*</div>*/}
      {/*</Page>*/}
    </IndexLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($lang: String) {
    page: contentfulPage(slug: { eq: "catalog" }, node_locale: { eq: $lang }) {
      ...PageFragment
    }
    products: allContentfulProduct(filter: { node_locale: { eq: $lang } }) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
    banners: allContentfulBanner(filter: { node_locale: { eq: $lang } }) {
      edges {
        node {
          id
          title
          ctaLabel
          image {
            fluid(sizes: "1600", quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
            xs: fluid(sizes: "480", quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
            md: fluid(sizes: "768", quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
            lg: fluid(sizes: "1200", quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
